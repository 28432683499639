import React from 'react';

import CommonHeaderRight from './CommonHeaderRight';
import Header, { HeaderLeft } from '../../../layout/Header/Header';

const DefaultHeader = () => {
	return (
		<Header isBackgroundTransparent>
			<HeaderLeft>
				<></>
			</HeaderLeft>
			<CommonHeaderRight></CommonHeaderRight>
		</Header>
	);
};

export default DefaultHeader;
