import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	data: null,
};

export const darkpoolSlice = createSlice({
	name: 'darkpool',
	initialState,
	reducers: {
		darkpoolRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		darkpoolSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.data = action.payload.data;
			state.data_info = action.payload.data_info;
		},
		darkpoolFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.data = null;
		},
		darkpoolRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.data = null;
		},
	},
});

export const { darkpoolRequest, darkpoolSuccess, darkpoolFailure, darkpoolRemove } =
	darkpoolSlice.actions;

export default darkpoolSlice.reducer;
