import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Icon from '../../../components/icon/Icon';

const LandingHeader = ({ onForum = false }) => {
	const navigate = useNavigate();

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const isPremium = useSelector((state) => state.me.user?.is_premium);

	return (
		<SubHeader>
			<SubHeaderLeft>
				<span className='fw-bold'>GhostBoard</span>
				<SubheaderSeparator />
				<span>Professional Trading Tools</span>
			</SubHeaderLeft>
			<SubHeaderRight>
				<Button
					color='info'
					icon='customDiscord'
					tag='a'
					isLink
					href='https://discord.gg/wHqFdf7sEa'>
					Discord
				</Button>
				{!isAuthenticated && (
					<>
						<Button
							color='success'
							isLight
							icon='Login'
							onClick={() => navigate(`/auth/login`)}>
							Login
						</Button>
						<Button color='info' icon='Add' onClick={() => navigate(`/auth/sign-up`)}>
							SignUp
						</Button>
					</>
				)}
				{isAuthenticated && (
					<>
						<Button
							color='info'
							icon='Dashboard'
							onClick={() => navigate(`/dashboard`)}>
							Dashboard
						</Button>
						{/*!onForum && (
							<Button
								color='warning'
								icon='Forum'
								onClick={() => navigate(`/forums`)}>
								Forums
							</Button>
						)*/}

						{!isPremium && (
							<Button icon='ShoppingCart' onClick={() => navigate(`/pricing`)}>
								Premium
							</Button>
						)}
					</>
				)}
				<Button
					color={darkModeStatus ? 'info' : 'warning'}
					icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
					isLight
					onClick={() => setDarkModeStatus(!darkModeStatus)}
				/>
			</SubHeaderRight>
		</SubHeader>
	);
};

export default LandingHeader;
