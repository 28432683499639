export const landingPages = {
	landing: {
		id: 'landing',
		text: 'Landing',
		path: '/',
		icon: 'sticky_note_2',
		title: '',
		description:
			'Advanced Dashboard Empowering Traders & Investors With Institutional Level Tools | Gain Your Edge Using Data',
		isProtected: false,
		isPremium: false,
	},
	stockdata: {
		id: 'stockdata',
		text: 'Stock Data',
		path: '/stockdata',
		icon: 'BarChart',
	},
	indicators: {
		id: 'indicators',
		text: 'Indicators',
		path: '/indicators',
		icon: 'CallMerge',
	},
	patterns: {
		id: 'patterns',
		text: 'Patterns',
		path: '/patterns',
		icon: 'Texture',
	},
	watchlists: {
		id: 'watchlists',
		text: 'Watchlists',
		path: '/watchlists',
		icon: 'Watchlists',
	},
	interests: {
		id: 'interests',
		text: 'Interests',
		path: '/interests',
		icon: 'Stars',
	},
	charts: {
		id: 'charts',
		text: 'Charts',
		path: '/charts',
		icon: 'ShowChart',
	},
	news: {
		id: 'news',
		text: 'News',
		path: '/news',
		icon: 'Feed',
	},
	termsofuse: {
		id: 'termsofuse',
		text: 'Terms of Use',
		path: '/termsofuse',
		icon: 'Gavel',
		title: 'Terms of Use',
		description:
			'Advanced Dashboard Empowering Traders & Investors With Institutional Level Tools | Gain Your Edge Using Data',
		isProtected: false,
		isPremium: false,
	},
};

export const dashboardPages = {
	dahsboardtools: {
		id: 'dashboardtools',
		text: 'Trading Tools',
		icon: 'Extension',
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
	},
	orderFlow: {
		id: 'orderFlow',
		text: 'Order Flow',
		path: '/dashboard/orderFlow',
		icon: 'AccountTree',
		isPremium: true,
	},
	hotFlow: {
		id: 'hotFlow',
		text: 'Hot Flow',
		path: '/dashboard/hotFlow',
		icon: 'AccountTree',
		isNew: true,
		hide: true,
	},
	darkpool: {
		id: 'darkpool',
		text: 'Darkpool',
		path: '/dashboard/darkpool',
		icon: 'GraphicEq',
		isPremium: true,
	},
	dailyWatchlist: {
		id: 'DailyWatchlist',
		text: 'Daily Watchlist',
		path: '/dashboard/dailyWatchlist',
		icon: 'EventAvailable',
		isPremium: true,
		hide: true,
	},
	scanners: {
		id: 'scanners',
		text: 'Scanners',
		path: '/dashboard/scanners',
		icon: 'LocationSearching',
		isPremium: true,
		isNew: true,
		// hide: true
	},
	news: {
		id: 'news',
		text: 'News',
		path: '/dashboard/news',
		icon: 'Feed',
		isPremium: true,
	 },
	gamma: {
		id: 'gamma',
		text: 'Gamma',
		path: '/dashboard/gamma',
		icon: 'Exposure',
		isPremium: true,
	},
	netPremium: {
		id: 'netPremium',
		text: 'Net Premium',
		path: '/dashboard/netpremium',
		icon: 'WaterfallChart',
		isPremium: true,
		hide: true
	},
	netFlow: {
		id: 'netFlow',
		text: 'Net Flow',
		path: '/dashboard/netflow',
		icon: 'WaterfallChart',
		isPremium: true,
		isNew: true,
	},
	calendar: {
		id: 'Calendar',
		text: 'Calendar',
		path: '/dashboard/calendar',
		icon: 'EventAvailable',
		isPremium: true,
		isNew: true,
	},
	education: {
		id: 'education',
		text: 'Education',
		icon: 'Extension',
	},
	tutorials: {
		id: 'tutorials',
		text: 'Tutorials',
		path: '/education/tutorials',
		icon: 'School',
		isPremium: true,
		isNew: true,
	},
	indicators: {
		id: 'indicators',
		text: 'Indicators',
		path: '/education/indicators',
		icon: 'StackedLineChart',
		isPremium: true,
		isNew: true,
	},
	indicatorDetails: {
		id: 'indicatorDetails',
		text: 'Indicators',
		path: '/education/indicatorDetails',
		icon: 'StackedLineChart',
		hide: true,
	},
	flowRecap: {
		id: 'flowRecap',
		text: 'Flow Recap',
		path: '/education/flowRecap',
		icon: 'Feed',
		isPremium: true,
		isNew: true,
		hide: true,
	},
	videos: {
		id: 'videos',
		text: 'Videos',
		path: '/education/videos',
		icon: 'OndemandVideo',
		isPremium: true,
		hide: true,
	},
	scripts: {
		id: 'scripts',
		text: 'Scripts',
		path: '/education/scripts',
		icon: 'Code',
		isPremium: true,
		hide: true,
	},
	others: {
		id: 'Others',
		text: 'Others',
		icon: 'Extension',
	},
	affiliate: {
		id: 'affiliate',
		text: 'Refer and Earn',
		path: '/affiliate',
		icon: 'AttachMoney',
		isGreen: true,
	},
};

export const authPages = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/auth/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: '/auth/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: '404',
		text: '404',
		path: '*',
		icon: 'SearchOff',
	},
};

export const pricingPages = {
	pricing: {
		id: 'pricing',
		text: 'Pricing',
		path: '/pricing',
		icon: 'Upgrade',
	},
};

export const configurationPages = {
	me: {
		id: 'me',
		text: 'Me',
		path: '/me/',
		icon: 'AccountCircle',
	},
	subscriptions: {
		id: 'subscriptions',
		text: 'Subscriptions',
		path: '/me/subscriptions/',
		icon: 'CardMembership',
	},
	configuration: {
		id: 'configuration',
		text: 'Configuration',
		path: '/me/configuration/',
		icon: 'Settings',
	},
	changePassword: {
		id: 'changePassword',
		text: 'Change Password',
		path: '/me/changePassword/',
		icon: 'Password',
	},
};

export const forumPages = {
	forum: {
		id: 'forum',
		text: 'Forums',
		path: '/forums/',
		icon: 'Forum',
	},
	trending: {
		id: 'trending',
		text: 'Trending',
		path: '/forums/topics/trending',
		icon: 'TrendingUp',
		hide: true
	},
	ourAnalysts: {
		id: 'ourAnalysts',
		text: 'Our Analysts',
		path: '/forums/our-analysts',
		icon: 'Analytics',
		
	},
	topics: {
		id: 'topics',
		text: 'Topics',
		path: '/forums/topics',
		icon: 'Topic',
	},

	forumTopic: {
		id: 'forumTopic',
		text: 'Topic',
		path: '/forums/topics/:topicSlug',
		hide: true,
	},

	forumCategory: {
		id: 'forumCategory',
		text: 'Topic',
		path: '/forums/:categorySlug',
		hide: true,
	},
	forumSubCategory: {
		id: 'forumSubCategory',
		text: 'SubCategory',
		path: '/forums/:categorySlug/:subCategorySlug',
		hide: true,
	},
	forumPost: {
		id: 'forumPost',
		text: 'Post',
		path: '/forums/:categorySlug/:subCategorySlug/:postSlug',
		hide: true,
	},
};

export const adminPages = {
	admin: {
		id: 'admin',
		text: 'Admin Dashboard',
		path: '/admin',
		icon: 'AdminPanelSettings',
	},
	restartUserPassword: {
		id: 'restartUserPassword',
		text: 'Restart User Password',
		path: '/admin/restartUserPassword',
		icon: 'LockOpen',
	},
	dashboard: {
		id: 'adminDashboard',
		text: 'Dashboard',
		path: '/admin/dashboard',
		icon: 'Dashboard',
	},
	flowRecap: {
		id: 'flowRecapPage',
		text: 'Flow Recap',
		path: '/admin/flowrecap',
		icon: 'Feed',
	},
};
