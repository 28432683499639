import { forwardRef, useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import showNotification from '../../components/extras/showNotification';

import { Helmet } from 'react-helmet';

const PageWrapper = forwardRef(
	(
		{
			isContentWrapper,
			isProtected,
			isPremium,
			isAdmin,
			NonPremiumComponent,
			neededPermissions,
			title,
			description,
			className,
			children,
		},
		ref,
	) => {
		/*useLayoutEffect(() => {
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
				process.env.REACT_APP_SITE_NAME
			}`;

			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});*/

		const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
		const isPremiumUser = useSelector((state) => state.me.user?.is_premium);
		const isAdminUser = useSelector((state) => state.me.user?.is_admin);

		const navigate = useNavigate();

		const [isLoading, setIsLoading] = useState(true);

		const [userIsntPremium, setUserIsntPremium] = useState(false);

		useEffect(() => {
			if (isProtected && !isAuthenticated && !isContentWrapper) {
				showNotification(
					<span className='d-flex align-items-center'>
						<span>Verification Failed</span>
					</span>,
					'You need to login',
					'danger',
				);

				navigate(`/auth/login`);
			}

			if (isPremium && !isPremiumUser && !isContentWrapper) {
				showNotification(
					<span className='d-flex align-items-center'>
						<span>This content is for premium users</span>
					</span>,
					'Subscribe to get all the content',
					'danger',
				);

				setUserIsntPremium(true);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}

			if (isAdmin && !isAdminUser && !isContentWrapper) {
				showNotification(
					<span className='d-flex align-items-center'>
						<span>What are you trying to do?</span>
					</span>,
					'',
					'warning',
				);

				navigate('/');
			}
		}, []);

		const permissions = useSelector((state) => state?.me?.user?.permissions);

		useEffect(() => {
			if (!isProtected || !neededPermissions) return;

			const hasPerms =
				permissions.filter((_perm) => {
					return neededPermissions?.includes(_perm);
				}).length > 0;

			if (!hasPerms) {
				showNotification(
					<span className='d-flex align-items-center'>
						<span>Verification Failed</span>
					</span>,
					'You dont have permissions to do that',
					'danger',
				);

				navigate(`/dashboard`);
			}
			return () => {};
		}, []);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				<Helmet>
					<meta property='og:type' content='website' />
					<title>{`${title ? `${title} | ` : ''}${
						process.env.REACT_APP_SITE_NAME
					}`}</title>
					<meta
						property='og:title'
						content={`${title ? `${title} | ` : ''}${process.env.REACT_APP_SITE_NAME}`}
					/>

					<meta
						name='description'
						content={description || process.env.REACT_APP_META_DESC || ''}
					/>
					<meta
						property='og:description'
						content={description || process.env.REACT_APP_META_DESC || ''}
					/>
				</Helmet>

				{isLoading ? (
					<></>
				) : userIsntPremium ? (
					NonPremiumComponent ? (
						<NonPremiumComponent />
					) : (
						<>Please upgrade your account to access this content</>
					)
				) : (
					<>{children}</>
				)}
			</div>
		);
	},
);

PageWrapper.displayName = 'PageWrapper';

PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	isPremium: PropTypes.bool,
	NonPremiumComponent: PropTypes.element,
	isContentWrapper: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

PageWrapper.defaultProps = {
	isProtected: true,
	isPremium: false,
	NonPremiumComponent: null,
	isContentWrapper: false,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
