import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	data: null,
};

export const adminDataAlice = createSlice({
	name: 'adminData',
	initialState,
	reducers: {
		adminDataRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		adminDataSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.data = action.payload;
		},
		adminDataFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.data = null;
		},
		adminDataRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.data = null;
		},
	},
});

export const { adminDataRequest, adminDataSuccess, adminDataFailure, adminDataRemove } =
	adminDataAlice.actions;

export default adminDataAlice.reducer;
