import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	topics: null,
};

export const forumTopicsSlice = createSlice({
	name: 'forumTopics',
	initialState,
	reducers: {
		topicsRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		topicsRequestSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.topics = action.payload;
		},
		topicsRequestFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.topics = null;
		},
		topicsRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.topics = null;
		},
	},
});

export const { topicsRequest, topicsRequestSuccess, topicsRequestFailure, topicsRemove } =
	forumTopicsSlice.actions;

export default forumTopicsSlice.reducer;
