import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	data: null,
	data_info: null,
};

export const volumeSlice = createSlice({
	name: 'volume',
	initialState,
	reducers: {
		volumeRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		volumeSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.data = action.payload.data;
			state.data_info = action.payload.data_info;
		},
		volumeFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.data = null;
			state.data_info = null;
		},
		volumeRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.data = null;
			state.data_info = null;
		},
	},
});

export const { volumeRequest, volumeSuccess, volumeFailure, volumeRemove } = volumeSlice.actions;

export default volumeSlice.reducer;
