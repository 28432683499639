import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Collapse from '../../components/bootstrap/Collapse';
import Icon from '../../components/icon/Icon';
import useDarkMode from '../../hooks/useDarkMode';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { logout } from '../../store/features/authSlice';
import { userRemove } from '../../store/features/meSlice';
import { NavigationLine } from '../Navigation/Navigation';
import Avatar from '../../components/Avatar';

const User = ({ asideProfileStatus, setAsideProfileStatus }) => {
	const dispatch = useDispatch();

	const isLoading = useSelector((state) => state.me.isLoading);
	const user = useSelector((state) => state.me.user);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const is_admin = useSelector((state) => state.me.user?.is_admin);

	return (
		<>
			<div
				className={classNames('user', { open: asideProfileStatus })}
				role='presentation'
				onClick={() => setAsideProfileStatus(!asideProfileStatus)}>
				<div className='user-avatar'>
					<Avatar src={user?.profile_image} />
				</div>
				<div className='user-info' style={{ overflow: 'hidden' }}>
					<div className='user-name d-flex align-items-center'>
						{`${user?.first_name}`}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					<div className='user-sub-title'>User</div>
				</div>
			</div>

			<Collapse isOpen={asideProfileStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/me`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>Profile</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/me/subscriptions`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='CardMembership' className='navigation-icon' />
									<span className='navigation-text'>Subscriptions</span>
								</span>
							</span>
						</div>
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/me/configuration`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Settings' className='navigation-icon' />
									<span className='navigation-text'>Configuration</span>
								</span>
							</span>
						</div> */}
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus ? 'DarkMode' : 'LightMode'}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						{is_admin && (
							<div
								className='navigation-item cursor-pointer'
								onClick={() => {
									navigate(`/admin`);
								}}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon='AdminPanelSettings'
											className='navigation-icon'
										/>
										<span className='navigation-text'>Admin</span>
									</span>
								</span>
							</div>
						)}

						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								dispatch(logout());
								dispatch(userRemove());

								navigate(`/auth/login`);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>Logout</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
