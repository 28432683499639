import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	data: null,
};

export const alertSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		alertRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		alertSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.data = action.payload;
		},
		alertFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.data = null;
		},
		alertRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.data = null;
		},
	},
});

export const { alertRequest, alertSuccess, alertFailure, alertRemove } = alertSlice.actions;

export default alertSlice.reducer;
