import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	data: null,
	data_info: null,
};

export const flowSlice = createSlice({
	name: 'flow',
	initialState,
	reducers: {
		flowRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		flowSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.data = action.payload.data;
			state.data_info = action.payload.data_info;
		},
		flowFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.data = null;
			state.data_info = null;
		},
		flowRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.data = null;
			state.data_info = null;
		},
	},
});

export const { flowRequest, flowSuccess, flowFailure, flowRemove } = flowSlice.actions;

export default flowSlice.reducer;
