import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    errors: null,
    meta: null,
};

export const forumMetaSlice = createSlice({
    name: 'forumMeta',
    initialState,
    reducers: {
        metaRequest: (state) => {
            state.isLoading = true;
            state.errors = null;
        },
        metaRequestSuccess: (state, action) => {
            state.isLoading = false;
            state.errors = null;
            state.meta = action.payload;
        },
        metaRequestFailure: (state, action) => {
            state.isLoading = false;
            state.errors = action.payload;
            state.meta = null;
        },
        metaRemove: (state) => {
            state.isLoading = false;
            state.errors = null;
            state.meta = null;
        },
    },
});

export const { metaRequest, metaRequestSuccess, metaRequestFailure, metaRemove } = forumMetaSlice.actions;

export default forumMetaSlice.reducer;
