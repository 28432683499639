import classNames from 'classnames';

import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';

const AdminFooter = () => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<span className='fw-light'>
							<b>Admin Dashboard</b> - Professional Trading Tools - Version 0.0.4
						</span>
					</div>
					<div className='col-auto'>
						<a
							href='/termsofuse'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>Terms of Use</small>
						</a>
					</div>
					<div className='col-auto'>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>GhostBoard</small>
						</a>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default AdminFooter;
