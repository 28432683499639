import React from 'react';

import { createRoot } from 'react-dom/client';
import './styles/styles.scss';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App/App';
import { ThemeContextProvider } from './contexts/themeContext';
import { store, persistor } from './store';

const children = (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeContextProvider>
				<Router>
					<App />
				</Router>
			</ThemeContextProvider>
		</PersistGate>
	</Provider>
);

const container = document.getElementById('root');

createRoot(container as Element).render(children);
