import React from 'react';

import { Route, Routes } from 'react-router-dom';

import contents from '../../routes/contentRoutes';

// const PAGE_404 = lazy(() => import('../../pages/auth/Page404'));

const ContentRoutes = () => {
	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
		</Routes>
	);
};

export default ContentRoutes;
