import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    errors: null,
    data: null,
};

export const exposureSlice = createSlice({
    name: 'exposure',
    initialState,
    reducers: {
        exposureRequest: (state) => {
            state.isLoading = true;
            state.errors = null;
        },
        exposureSuccess: (state, action) => {
            state.isLoading = false;
            state.errors = null;
            state.data = action.payload;
        },
        exposureFailure: (state, action) => {
            state.isLoading = false;
            state.errors = action.payload;
            state.data = null;
        },
        exposureRemove: (state) => {
            state.isLoading = false;
            state.errors = null;
            state.data = null;
        },
    },
});

export const { exposureRequest, exposureSuccess, exposureFailure, exposureRemove } = exposureSlice.actions;

export default exposureSlice.reducer;
