import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	categories: null,
};

export const forumCategoriesSlice = createSlice({
	name: 'forumCategories',
	initialState,
	reducers: {
		categoriesRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		categoriesRequestSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.categories = action.payload;
		},
		categoriesRequestFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.categories = null;
		},
		categoriesRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.categories = null;
		},

		categoryRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		categoryRequestSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;

			if (!state.categories) {
				state.categories = [action.payload];
			} else {
				var objIndex = state.categories.findIndex(
					(element) => element.slug == action.payload.slug,
				);

				if (objIndex == -1) state.categories.push(action.payload);
				else state.categories[objIndex] = action.payload;
			}
		},
		categoryRequestFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.categories = null;
		},

		subCategoryRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		subCategoryRequestSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;

			var categoryIndex = state.categories.findIndex(
				(element) => element.slug == action.payload.parent,
			);

			var subCategoryIndex = state.categories[categoryIndex].sub_categories.findIndex(
				(element) => element.slug == action.payload.slug,
			);

			if (subCategoryIndex == -1) state.categories.push(action.payload);
			else state.categories[categoryIndex].sub_categories[subCategoryIndex] = action.payload;
		},
		subCategoryRequestFailure: (state) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.categories = null;
		},
	},
});

export const {
	categoriesRequest,
	categoriesRequestSuccess,
	categoriesRequestFailure,
	categoriesRemove,
	categoryRequest,
	categoryRequestSuccess,
	categoryRequestFailure,
	subCategoryRequest,
	subCategoryRequestSuccess,
	subCategoryRequestFailure,
} = forumCategoriesSlice.actions;

export default forumCategoriesSlice.reducer;
