import React, { useContext, useEffect } from 'react';

import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { forumPages } from '../../../menu';

const ForumAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { asideProfileStatus, setAsideProfileStatus } = useContext(ThemeContext);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation
					menu={{
						...forumPages,
					}}
					id='aside-dashboard'
				/>
			</AsideBody>
			<AsideFoot>
				<Navigation
					menu={{
						...{
							dashboard: {
								id: 'dashboard',
								text: 'Dashboard',
								path: '/dashboard',
								icon: 'Dashboard',
							},
						},
					}}
					id='aside-dashboard-bottom'
				/>
				<User
					asideProfileStatus={asideProfileStatus}
					setAsideProfileStatus={setAsideProfileStatus}
				/>
			</AsideFoot>
		</Aside>
	);
};

export default ForumAside;
