import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	broadcast: null,
};

export const broadcastSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		broadcastRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		broadcastSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.broadcast = action.payload;
		},
		broadcastFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.broadcast = null;
		},
		broadcastRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.broadcast = null;
		},
	},
});

export const { broadcastRequest, broadcastSuccess, broadcastFailure, broadcastRemove } =
	broadcastSlice.actions;

export default broadcastSlice.reducer;
