import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	user: null,
};

export const meSlice = createSlice({
	name: 'me',
	initialState,
	reducers: {
		userRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		userRequestSuccess: (state, action) => {
			state.isLoading = false;
			state.errors = null;
			state.user = action.payload;
		},
		userRequestFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.user = null;
		},
		userRemove: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.user = null;
		},
	},
});

export const { userRequest, userRequestSuccess, userRequestFailure, userRemove } = meSlice.actions;

export default meSlice.reducer;
