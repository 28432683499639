import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	errors: null,
	isAuthenticated: false,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginRequest: (state) => {
			state.isLoading = true;
			state.errors = null;
		},
		loginSuccess: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.isAuthenticated = true;
		},
		loginFailure: (state, action) => {
			state.isLoading = false;
			state.errors = action.payload;
			state.isAuthenticated = false;
		},
		logout: (state) => {
			state.isLoading = false;
			state.errors = null;
			state.isAuthenticated = false;
		},
	},
});

export const { loginRequest, loginSuccess, loginFailure, logout } = authSlice.actions;

export default authSlice.reducer;
